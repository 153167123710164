import { motion } from 'framer-motion';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Services from './components/Services';
import Footer from './components/Footer';
import About from './components/About';
import Contact from './components/Contact';
import FloatingButtons from './components/FloatingButtons';
import ChatSystem from './components/ChatSystem';
import './components/Navbar.css';
import './components/Hero.css';
import './components/Services.css';
import './components/About.css';
import './components/Contact.css';
import './components/Footer.css';
import './components/FloatingButtons.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <About />
      <Services />
      <Contact />
      <Footer />
      <FloatingButtons />
      <ChatSystem />
    </div>
  );
}

export default App;