import { motion } from 'framer-motion';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaUser, FaPaperPlane, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import './Contact.css';

const Contact = () => {
  return (
    <section id="contact" className="contact-section">
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="text-center mb-5"
        >
          <h2 className="section-title">Contact</h2>
          <div className="title-underline"></div>
        </motion.div>

        <div className="row g-4">
          <div className="col-lg-4">
            <motion.div
              className="contact-info"
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
            >
              <div className="info-card">
                <div className="info-item">
                  <FaMapMarkerAlt className="info-icon" />
                  <div className="info-content">
                    <h3>Sediu București</h3>
                    <p>Strada Curtea Domneasca, Nr. 4, Bloc A7, Sc. A, Ap. 8, Suceava, Județ Suceava</p>
                  </div>
                </div>

                <div className="info-item">
                  <FaPhone className="info-icon" />
                  <div className="info-content">
                    <h3>Telefon</h3>
                    <p><a href="tel:0740326673">0740326673</a></p>
                  </div>
                </div>

                <div className="info-item">
                  <FaEnvelope className="info-icon" />
                  <div className="info-content">
                    <h3>Email</h3>
                    <p><a href="mailto:marinescubogdan87@yahoo.com">marinescubogdan87@yahoo.com</a></p>
                  </div>
                </div>

                <div className="info-item">
                  <FaFacebookF className="info-icon" />
                  <div className="info-content">
                    <h3>Facebook</h3>
                    <p><a href="https://www.facebook.com/profile.php?id=61569235961532" target="https://www.facebook.com/profile.php?id=61569235961532" rel="noopener noreferrer">Vizitează Pagina</a></p>
                  </div>
                </div>

                <div className="info-item">
                  <FaLinkedinIn className="info-icon" />
                  <div className="info-content">
                    <h3>LinkedIn</h3>
                    <p><a href="https://www.linkedin.com/in/building-planted-466b7233a/" target="https://www.linkedin.com/in/building-planted-466b7233a/" rel="noopener noreferrer">Vezi Profilul</a></p>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>

          <div className="col-lg-8">
            <div className="row g-4">
              <div className="col-12">
                <motion.div
                  className="contact-form-container"
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6 }}
                  viewport={{ once: true }}
                >
                  {/* <form className="contact-form">
                    <div className="row g-4">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="input-icon">
                            <FaUser />
                          </div>
                          <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Numele dvs."
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="input-icon">
                            <FaEnvelope />
                          </div>
                          <input 
                            type="email" 
                            className="form-control" 
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <textarea 
                            className="form-control" 
                            rows="4" 
                            placeholder="Mesajul dvs."
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-12">
                        <motion.button 
                          type="submit" 
                          className="submit-btn"
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.98 }}
                        >
                          <FaPaperPlane className="me-2" />
                          Trimite Mesaj
                        </motion.button>
                      </div>
                    </div>
                  </form> */}
                </motion.div>
              </div>
              
              <div className="col-12">
                <motion.div
                  className="map-container"
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 0.2 }}
                  viewport={{ once: true }}
                >
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2713.5051994675567!2d26.2572425!3d47.646364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4734fc3a8f6dd997%3A0xf564137096abb928!2sBloc%20A7%2C%20Sc.%20A%2C%20Ap.%208%2C%20Bloc%207%2C%20Strada%20Curtea%20Domneasc%C4%83%204%2C%20Suceava%20720004!5e0!3m2!1sen!2sro!4v1690000000000!5m2!1sen!2sro"
                    width="100%"
                    height="300"
                    style={{ border: 0, borderRadius: '20px' }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;