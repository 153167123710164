import { motion } from 'framer-motion';
import './Hero.css';
import heroVideo from '../assets/hero-building-planted.mp4';

const Hero = () => {
  return (
    <section id="acasa" className="hero-section">
      <div className="video-container">
        <video autoPlay loop muted playsInline className="hero-video">
          <source src={heroVideo} type="video/mp4" />
        </video>
      </div>
      
      <div className="hero-overlay"></div>
      
      <div className="container">
        <div className="hero-content">
          <h1 className="hero-title">
          Soluții verzi. <span className="highlight">Construcții durabile</span>
          </h1>
          
          {/* <p className="hero-subtitle">
            Sustainable architecture that harmonizes with the forest environment
          </p> */}

          
        </div>
      </div>
    </section>
  );
};

export default Hero;