import { motion } from 'framer-motion';
import { FaSeedling, FaSolarPanel, FaRecycle, FaBolt } from 'react-icons/fa';
import './Services.css';

const Services = () => {
    const services = [
        {
          icon: <FaSeedling />,
          title: "Proiectare Verde",
          description: "Soluții de design sustenabil și eficient energetic",
          color: "#90EE90"
        },
        {
          icon: <FaSolarPanel />,
          title: "Sisteme Sustenabile",
          description: "Implementare sisteme de energie regenerabilă",
          color: "#7FCD7F"
        },
        {
          icon: <FaRecycle />,
          title: "Materiale Eco",
          description: "Utilizare materiale reciclabile și sustenabile",
          color: "#6EAC6E"
        },
        {
          icon: <FaBolt />,
          title: "Eficiență Energetică",
          description: "Optimizare consum și performanță energetică",
          color: "#5D8B5D"
        }
      ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: 50
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  return (
    <section id="servicii" className="services-section">
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="text-center mb-5"
        >
          <h2 className="section-title">Serviciile Noastre</h2>
          <div className="title-underline"></div>
        </motion.div>

        <motion.div 
          className="row g-4"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          {services.map((service, index) => (
            <div key={index} className="col-md-6 col-lg-3">
              <motion.div 
                className="service-card"
                variants={cardVariants}
                whileHover={{ 
                  y: -10,
                  transition: { duration: 0.2, ease: "easeOut" }
                }}
              >
                <motion.div 
                  className="icon-wrapper"
                  style={{ backgroundColor: service.color }}
                  whileHover={{ 
                    rotate: 360,
                    scale: 1.1,
                    transition: { duration: 0.6 }
                  }}
                >
                  {service.icon}
                </motion.div>
                <h3 className="service-title">{service.title}</h3>
                <p className="service-description">{service.description}</p>
                {/* <motion.button 
                  className="learn-more-btn"
                  whileHover={{ 
                    scale: 1.05,
                    backgroundColor: service.color 
                  }}
                  whileTap={{ scale: 0.95 }}
                >
                  Află Mai Multe
                </motion.button> */}
              </motion.div>
            </div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Services;