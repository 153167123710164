import React from "react";
import euro1 from "../assets/euro1.png";
import euro2 from "../assets/euro2.png";
import euro3 from "../assets/euro3.png";
import euro4 from "../assets/euro4.png";
import pdfFile from "../assets/DIGITALIZARE BUILDING-PLANTED SRL.pdf";

const Footer = () => {
  const handleInfoClick = () => {
    window.open(pdfFile, "_blank"); // Am adăugat '_blank' pentru a deschide într-o filă nouă
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <div className="links-container">
            <a href="#" className="footer-nav-link">
              Întrebări frecvente
            </a>
            <a href="#" className="footer-nav-link">
              Politica de cookies
            </a>
            <a href="#" className="footer-nav-link">
              Politica de confidențialitate
            </a>
            <a href="#" className="footer-nav-link">
              Hartă site
            </a>
            <a href="#" className="footer-nav-link">
              Contact
            </a>
          </div>
        </div>

        <div className="footer-right">
          <div className="info-text">
            Pentru informații detaliate despre celelalte programe cofinanțate de
            Uniunea Europeană, vă invităm să vizitați{" "}
            <a href="https://mfe.gov.ro" className="mfe-link">
              mfe.gov.ro
            </a>
            .
          </div>
          <div className="web-links">
            <div className="url-links">
              <a href="https://www.regionordest.ro" className="web-link">
                www.regionordest.ro
              </a>
              <a
                href="https://www.facebook.com/Regio.NordEst.ro"
                className="web-link"
              >
                www.facebook.com/Regio.NordEst.ro
              </a>
            </div>
            <button className="aproape-button">Aproape de tine</button>
          </div>
        </div>
      </div>

      <div className="footer-banner">
        <div className="logo-row">
          <img src={euro1} alt="Euro Logo 1" className="banner-logo" />
          <img src={euro2} alt="Euro Logo 2" className="banner-logo" />
          <img src={euro3} alt="Euro Logo 3" className="banner-logo" />
          <img src={euro4} alt="Euro Logo 4" className="banner-logo" />
        </div>
        <button onClick={handleInfoClick} className="info-button">
          Proiect digitalizare - Află mai multe
        </button>
      </div>
    </footer>
  );
};

export default Footer;
