import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import './Navbar.css';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleLogin = () => {
    closeMenu();
    window.location.href = 'https://dashboard.building-forest.com';
    // Alternative: window.open('https://dashboard.aipro.ro/home', '_blank'); // Opens in new tab
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`navbar navbar-expand-lg fixed-top ${isScrolled ? 'scrolled' : ''}`}>
      <div className="container position-relative">
        <a className="navbar-brand fw-bold" href="#">
          <span className="text-success">Building</span>
          <span className="text-primary">Planted</span>
        </a>

        <button 
          className={`navbar-toggler ${isOpen ? '' : 'collapsed'}`}
          type="button" 
          onClick={toggleMenu}
          aria-expanded={isOpen}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav mx-auto">
            {['Acasa', 'Despre', 'Servicii', 'Contact'].map((item) => (
              <li key={item} className="nav-item">
                <a 
                  className="nav-link px-3" 
                  href={`#${item.toLowerCase()}`}
                  onClick={closeMenu}
                >
                  {item}
                </a>
              </li>
            ))}
          </ul>
          <button 
            className="login-btn" 
            onClick={handleLogin}
          >
            Login
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;