import { FaPhone, FaWhatsapp } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import './FloatingButtons.css';

const FloatingButtons = () => {
    const [isHovered, setIsHovered] = useState(null);
    const phoneNumber = "+40740326673"; // Replace with your actual phone number

    const buttonVariants = {
        initial: { 
            x: 100, 
            opacity: 0 
        },
        animate: { 
            x: 0, 
            opacity: 1,
            transition: {
                type: "spring",
                stiffness: 100,
                damping: 15
            }
        },
        hover: { 
            scale: 1.1,
            rotate: [0, -10, 10, -10, 0],
            transition: {
                rotate: {
                    duration: 0.5,
                    ease: "easeInOut"
                }
            }
        },
        tap: { 
            scale: 0.9 
        }
    };

    const tooltipVariants = {
        initial: { 
            opacity: 0, 
            x: 20 
        },
        animate: { 
            opacity: 1, 
            x: 0,
            transition: {
                duration: 0.3
            }
        },
        exit: { 
            opacity: 0, 
            x: 20,
            transition: {
                duration: 0.2
            }
        }
    };

    return (
        <div className="floating-buttons">
            <motion.a
                href={`tel:${phoneNumber}`}
                className="floating-button phone-button"
                variants={buttonVariants}
                initial="initial"
                animate="animate"
                whileHover="hover"
                whileTap="tap"
                onHoverStart={() => setIsHovered('phone')}
                onHoverEnd={() => setIsHovered(null)}
                transition={{ delay: 0.2 }}
            >
                <FaPhone className="button-icon" />
                <AnimatePresence>
                    {isHovered === 'phone' && (
                        <motion.span 
                            className="button-tooltip"
                            variants={tooltipVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                        >
                            Sună Acum
                        </motion.span>
                    )}
                </AnimatePresence>
            </motion.a>

            <motion.a
                href={`https://wa.me/${phoneNumber}`}
                target="_blank"
                rel="noopener noreferrer"
                className="floating-button whatsapp-button"
                variants={buttonVariants}
                initial="initial"
                animate="animate"
                whileHover="hover"
                whileTap="tap"
                onHoverStart={() => setIsHovered('whatsapp')}
                onHoverEnd={() => setIsHovered(null)}
                transition={{ delay: 0.3 }}
            >
                <FaWhatsapp className="button-icon" />
                <AnimatePresence>
                    {isHovered === 'whatsapp' && (
                        <motion.span 
                            className="button-tooltip"
                            variants={tooltipVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                        >
                            WhatsApp
                        </motion.span>
                    )}
                </AnimatePresence>
            </motion.a>
        </div>
    );
};

export default FloatingButtons;