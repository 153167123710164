import { motion } from 'framer-motion';
import { FaLeaf, FaRecycle, FaSeedling } from 'react-icons/fa';
import './About.css';

const About = () => {
  const features = [
    {
      icon: <FaLeaf />,
      title: "Inovație Verde",
      description: "Tehnologii moderne pentru construcții eco-friendly"
    },
    {
      icon: <FaRecycle />,
      title: "Sustenabilitate",
      description: "Materiale și procese durabile pentru viitor"
    },
    {
      icon: <FaSeedling />,
      title: "Design Adaptabil",
      description: "Soluții personalizate pentru fiecare proiect"
    }
  ];

  return (
    <section id="despre" className="about-section">
      <div className="container">
        <div className="row align-items-center">
          <motion.div 
            className="col-lg-6"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <div className="about-content">
              <motion.h2 
                className="section-title"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.6 }}
              >
                Soluții verzi pentru construcții durabile
              </motion.h2>
              
              <motion.div 
                className="title-underline"
                initial={{ width: 0 }}
                whileInView={{ width: "80px" }}
                transition={{ delay: 0.4, duration: 0.6 }}
              />

              <motion.p 
                className="about-text"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6, duration: 0.6 }}
              >
                Creăm spații verzi și sustenabile, integrând inovația
                tehnologică și designul eficient pentru a construi viitorul
                într-un mod responsabil și durabil. Ne angajăm să oferim soluții
                adaptabile care să răspundă cerințelor complexe ale fiecărui
                proiect.
              </motion.p>

              {/* <motion.button 
                className="learn-more-btn"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Află mai multe
              </motion.button> */}
            </div>
          </motion.div>

          <motion.div 
            className="col-lg-6"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <div className="features-grid">
              {features.map((feature, index) => (
                <motion.div 
                  key={index}
                  className="feature-card"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2, duration: 0.6 }}
                  whileHover={{ y: -10 }}
                >
                  <div className="feature-icon">
                    {feature.icon}
                  </div>
                  <h3 className="feature-title">{feature.title}</h3>
                  <p className="feature-description">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      <div className="about-bg-elements">
        <div className="leaf-1"></div>
        <div className="leaf-2"></div>
        <div className="circle-1"></div>
        <div className="circle-2"></div>
      </div>
    </section>
  );
};

export default About;